import React, {Component } from 'react'
import {connect} from 'react-redux'
import 'antd-mobile-v5/es/global'
import PT from 'prop-types'
import Helmet from 'react-helmet'
import DarkMask from './components/DarkMask'
// import VConsole from 'vconsole';

class App extends Component {
  vConsoleInitialized = false; // 添加一个标志位
  componentDidMount() {
    // app 级别的公用逻辑可以放到这里
    // 比如白天、夜间模式功能
    const { isDebug } = this.props.deviceInfo
    console.log("🚀 ~ App ~ componentDidMount ~ isDebug:", isDebug)
    if (Number(isDebug) === 1 && !this.vConsoleInitialized) {
        import('vconsole').then(({ default: VConsole }) => {
          new VConsole({
            pluginList: [] // 禁用插件
          });
          this.vConsoleInitialized = true
          console.log('vConsole initialized');
      }).catch((err) => {
          console.error('Failed to load vConsole:', err);
      });
    }
  }

  render() {
   
    return (
      <div className="AppMain">
        <Helmet>
          <title />
        </Helmet>
        {this.props.children}
        <DarkMask />
      </div>
    )
  }
}

App.propTypes = {
  children: PT.node.isRequired,
  deviceInfo: PT.any.isRequired,
}

export default connect(state => {
  const deviceInfo = state.deviceinfo
  return {deviceInfo}
})(App)

