import {matchRoutes} from 'react-router-config'

// https://github.com/jamiebuilds/react-loadable/blob/a64a415d1bff33ca5ef35f2abf616f47085a93a2/src/index.js#L92
// eslint-disable-next-line no-underscore-dangle
const resolve = obj => (obj && obj.__esModule ? obj.default : obj)

const fetchDataBeforeRender = async ({
  routes,
  store,
  url,
  query,
  onProgress,
  lang // 新增参数 lang
}) => {
  const branch = matchRoutes(routes, url)
  onProgress && onProgress({progress: 0})
  const promises = branch.map(async ({route, match}) => {
    let Comp = route.component

    // react-loadable wrapped
    if (Comp.preload) {
      Comp = resolve(await Comp.preload())
    }
    return Comp.fetchData
      ? Comp.fetchData(store, match, {...query, lang}) // 新增参数 lang
      : Promise.resolve(null)
  })

  const responses = await Promise.all(promises)
  onProgress && onProgress({progress: 100})
  return responses
}

const _ = ''

export default _
export {fetchDataBeforeRender}
